import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import { getPost } from '../blog/browserAdapter';
import { marked } from 'marked';
import '../blog/blog.css';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<{ 
    title: string;
    date: string;
    description: string;
    contentHtml: string;
    tags?: string[];
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      if (!slug) {
        setError('Post not found');
        setLoading(false);
        return;
      }

      try {
        // Get post from our browser adapter
        const postData = getPost(slug);
        
        if (!postData) {
          setError('Post not found');
          setLoading(false);
          return;
        }
        
        // Convert markdown to HTML using marked
        const contentHtml = marked(postData.content);
        
        setPost({
          title: postData.title,
          date: postData.date,
          description: postData.description,
          tags: postData.tags,
          contentHtml: contentHtml.toString()
        });
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError('Failed to load blog post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  // Format the date for display
  const formattedDate = post?.date
    ? new Date(`${post.date}T12:00:00Z`).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '';

  return (
    <section className="pt-28 pb-20 relative overflow-hidden">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
          </div>
        ) : error ? (
          <div className="text-center py-16">
            <h2 className="text-2xl font-bold text-white mb-4">Error</h2>
            <p className="text-white/70 mb-8">{error}</p>
            <Link
              to="/blog"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Back to Blog
            </Link>
          </div>
        ) : post ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mb-4">
              <Link 
                to="/blog" 
                className="text-indigo-400 hover:text-indigo-300 transition-colors duration-150 inline-flex items-center"
              >
                <svg 
                  className="w-4 h-4 mr-2" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                Back to Blog
              </Link>
            </div>

            <article>
              <div className="mb-8">
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">{post.title}</h1>
                <div className="text-indigo-300 text-sm font-medium mb-4">{formattedDate}</div>
                
                {/* Add tags display */}
                {post.tags && post.tags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {post.tags.map(tag => (
                      <Link 
                        key={tag}
                        to={`/blog?tag=${encodeURIComponent(tag)}`}
                        className="text-sm px-3 py-1 rounded-full bg-indigo-900/50 text-indigo-300 border border-indigo-800/50 hover:bg-indigo-800/50 transition-colors"
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <div 
                className="blog-content bg-white/10 backdrop-blur-sm p-8 rounded-xl border border-white/10"
                dangerouslySetInnerHTML={{ __html: post.contentHtml }} 
              />
            </article>
          </motion.div>
        ) : (
          <div className="text-center py-16">
            <p className="text-white/70 text-lg">Post not found</p>
            <Link
              to="/blog"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Back to Blog
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogPost; 