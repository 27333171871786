import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import BlogCard from './BlogCard';
import { getSortedPosts } from '../blog/browserAdapter';
import type { PostMeta } from '../blog/browserAdapter';

const BlogList: React.FC = () => {
  const [posts, setPosts] = useState<PostMeta[]>([]);
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [activeTag, setActiveTag] = useState<string | null>(null);
  const { scrollY } = useScroll();
  const location = useLocation();
  
  // Start with full opacity (1) and fade slightly as scroll begins
  // This ensures the title is visible immediately
  const titleOpacity = useTransform(scrollY, [0, 100], [1, 0.9]);
  
  // Start at position 0 and move slightly as scroll begins
  const titleY = useTransform(scrollY, [0, 100], [0, -5]);

  // Get all unique tags from posts
  const allTags = React.useMemo(() => {
    if (!posts.length) return [];
    const tags = new Set<string>();
    posts.forEach(post => {
      if (post.tags) {
        post.tags.forEach(tag => tags.add(tag));
      }
    });
    return Array.from(tags).sort();
  }, [posts]);
  
  // Filter posts by tag
  const filteredPosts = React.useMemo(() => {
    if (!activeTag) return posts;
    return posts.filter(post => post.tags?.includes(activeTag));
  }, [posts, activeTag]);

  useEffect(() => {
    try {
      // Get posts from our browser adapter
      const allPosts = getSortedPosts();
      console.log('Loaded posts:', allPosts);
      console.log('Posts have tags?', allPosts.some(post => post.tags && post.tags.length > 0));
      if (allPosts.length > 0) {
        console.log('First post tags:', allPosts[0].tags);
      }
      setPosts(allPosts);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Parse tag from URL query parameter
    const searchParams = new URLSearchParams(location.search);
    const tagParam = searchParams.get('tag');
    if (tagParam) {
      setActiveTag(tagParam);
    } else {
      setActiveTag(null);
    }
  }, [location.search]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 80;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  // Debug log for tags
  console.log('All tags:', allTags);
  console.log('Active tag:', activeTag);
  console.log('Filtered posts:', filteredPosts);

  return (
    <section id="blog" className="pt-28 pb-20 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 1, y: 0 }}
          style={{ opacity: titleOpacity, y: titleY }}
          className="text-center mb-16"
        >
          <div className="inline-block">
            <motion.div
              className="h-1 w-20 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full mb-4 mx-auto"
              initial={{ width: 0 }}
              animate={{ width: 80 }}
              transition={{ duration: 0.8 }}
            />
            <h2 className="text-4xl font-bold text-white mb-4">
              The <span className="text-yellow-400" style={{ textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)' }}>Blog</span>
            </h2>
          </div>
          <p className="text-xl text-white/80 max-w-3xl mx-auto leading-relaxed">
            Insights and strategies for digital engagement, marketing automation, and compliance.
          </p>
        </motion.div>

        {/* Tag filters */}
        {allTags.length > 0 && (
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 justify-center">
              <button
                onClick={() => {
                  setActiveTag(null);
                  window.history.pushState({}, '', '/blog');
                }}
                className={`text-sm px-3 py-1 rounded-full transition-colors ${
                  activeTag === null 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-slate-800/80 text-white/80 hover:bg-slate-700/80'
                }`}
              >
                All Posts
              </button>
              
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => {
                    setActiveTag(tag);
                    window.history.pushState({}, '', `/blog?tag=${encodeURIComponent(tag)}`);
                  }}
                  className={`text-sm px-3 py-1 rounded-full transition-colors ${
                    activeTag === tag 
                      ? 'bg-indigo-600 text-white' 
                      : 'bg-slate-800/80 text-white/80 hover:bg-slate-700/80'
                  }`}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
          </div>
        ) : filteredPosts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredPosts.map((post, index) => (
              <BlogCard key={post.slug} post={post} index={index} />
            ))}
          </div>
        ) : (
          <div className="text-center py-16">
            {activeTag ? (
              <>
                <p className="text-white/70 text-lg mb-4">No blog posts found with tag "{activeTag}".</p>
                <button
                  onClick={() => {
                    setActiveTag(null);
                    window.history.pushState({}, '', '/blog');
                  }}
                  className="text-indigo-400 hover:text-indigo-300 transition-colors duration-150"
                >
                  View all posts
                </button>
              </>
            ) : (
              <p className="text-white/70 text-lg">No blog posts found. Check back soon!</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogList; 