import React from 'react';
import { motion } from 'framer-motion';

interface SuccessMessageProps {
  onClose: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="text-center p-6"
    >
      <div className="mb-4 flex justify-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ 
            type: "spring", 
            stiffness: 500, 
            damping: 20, 
            mass: 0.5, 
            delay: 0.05 
          }}
          className="w-20 h-20 bg-green-500 rounded-full flex items-center justify-center"
        >
          <motion.svg 
            className="w-10 h-10 text-white" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
            initial={{ pathLength: 0, opacity: 0 }}
            animate={{ pathLength: 1, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.15 }}
          >
            <motion.path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="3" 
              d="M5 13l4 4L19 7"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.3, delay: 0.15 }}
            ></motion.path>
          </motion.svg>
        </motion.div>
      </div>
      
      <motion.h3 
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.1 }}
        className="text-2xl font-bold text-white mb-2"
      >
        Message Sent!
      </motion.h3>
      
      <motion.p
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.15 }}
        className="text-white/70 mb-6"
      >
        Thanks for reaching out. I'll get back to you within 24 hours.
      </motion.p>
      
      <motion.button
        onClick={onClose}
        className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-medium py-2 px-6 rounded-lg shadow hover:shadow-blue-500/20"
        whileHover={{ scale: 1.05, transition: { duration: 0.15 } }}
        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.2 }}
      >
        Close
      </motion.button>
    </motion.div>
  );
};

export default SuccessMessage; 