import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import ContactForm from './ContactForm';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    // Add event listener for the Escape key
    document.addEventListener('keydown', handleEscape);

    // Handle focus trap
    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;
        
        // If shift+tab and on first element, move to last
        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        } 
        // If tab and on last element, move to first
        else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
        }
      }
    };

    document.addEventListener('keydown', handleTabKey);

    // Focus the close button when modal opens - make it happen faster
    if (isOpen && initialFocusRef.current) {
      // Reduced timeout for quicker focus
      setTimeout(() => initialFocusRef.current?.focus(), 50);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('keydown', handleTabKey);
    };
  }, [isOpen, onClose]);

  // Don't render anything if modal is not open
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Backdrop - faster fade in */}
      <motion.div 
        className="fixed inset-0 bg-black/70 backdrop-blur-sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }} // Faster backdrop transition
        onClick={onClose}
      />

      {/* Modal Container - centering */}
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        {/* Modal Content - more responsive animation */}
        <motion.div
          ref={modalRef}
          className="relative transform overflow-hidden rounded-2xl bg-slate-800 border border-white/10 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          initial={{ opacity: 0, scale: 0.95, y: 8 }} // Less initial movement
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.97, y: 8 }} // Subtle exit animation
          transition={{ 
            type: "spring", 
            stiffness: 500, // Higher stiffness for snappier motion
            damping: 25, // Less damping for more responsive feel
            mass: 0.5, // Lower mass for faster response
            duration: 0.2 // Shorter overall duration
          }}
        >
          {/* Close button with improved hover effect */}
          <button
            ref={initialFocusRef}
            type="button"
            className="absolute right-4 top-4 rounded-full p-1.5 bg-white/10 hover:bg-white/30 transition-colors duration-100 focus:outline-none focus:ring-2 focus:ring-blue-500 z-10"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Modal Body with subtle entrance animation */}
          <motion.div 
            className="bg-gradient-to-b from-slate-800 to-slate-900 px-6 py-8"
            initial={{ opacity: 0.9 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
          >
            <ContactForm onClose={onClose} />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactModal; 