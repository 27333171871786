import React from 'react';

// Fixed background container for the entire site
export const FixedBackground: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-slate-900 -z-10 overflow-hidden">
      {/* Subtle starfield effect */}
      <div className="absolute inset-0 opacity-30">
        {Array.from({ length: 100 }).map((_, i) => {
          const size = Math.random() * 2;
          const top = Math.random() * 100;
          const left = Math.random() * 100;
          const animationDelay = Math.random() * 10;
          const opacity = Math.random() * 0.7 + 0.3;
          
          return (
            <div 
              key={i}
              className="absolute rounded-full bg-white"
              style={{
                width: `${size}px`,
                height: `${size}px`,
                top: `${top}%`,
                left: `${left}%`,
                opacity: opacity,
                animation: `twinkle 4s infinite ${animationDelay}s`
              }}
            />
          );
        })}
      </div>
      
      <style>
        {`
          @keyframes twinkle {
            0%, 100% { opacity: 0.3; }
            50% { opacity: 0.7; }
          }
        `}
      </style>
    </div>
  );
}; 