import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FixedBackground } from './index';
import Header from './Header';

// Use the same data from About.tsx
const education = [
  {
    school: 'Georgia Institute of Technology',
    degree: 'Master\'s degree, Analytics',
    period: 'Jan 2022 - Dec 2025',
    color: 'from-yellow-500 to-yellow-400', // GT colors
    logo: '/img/Georgia_Tech_Yellow_Jackets_logo.svg.png'
  },
  {
    school: 'University of California, Berkeley',
    degree: 'Bachelor\'s degree, Physics',
    period: '2010 - 2014',
    color: 'from-blue-600 to-yellow-500', // Berkeley colors
    logo: '/img/Seal_of_University_of_California,_Berkeley.svg.png'
  },
  {
    school: 'University of California, Berkeley',
    degree: 'Bachelor\'s degree, Astrophysics',
    period: '2010 - 2014',
    color: 'from-blue-600 to-yellow-500', // Berkeley colors
    logo: '/img/Seal_of_University_of_California,_Berkeley.svg.png'
  },
];

const experience = [
  {
    role: 'Director - Engagement Product',
    company: 'mPulse Mobile',
    period: '2021 – Present',
    description: 'Leading product strategy for self-service tools, HIPAA-compliant communications, and engagement solutions that deliver measurable outcomes. Developed scalable data architectures, system governance practices, and innovative compliance frameworks.',
    logo: '/img/Logo-mPulse.svg'
  },
  {
    role: 'Product Manager',
    company: 'Crowdz',
    period: '2020 – 2021',
    description: 'Implemented invoice financing tools to enhance partner monetization and enable small businesses. Designed blockchain-based verification systems for financial document authenticity.',
    logo: '/img/Crowdz.png'
  },
  {
    role: 'Senior Product Manager',
    company: 'Experian',
    period: '2019 – 2020',
    description: 'Optimized platform performance and integrated advanced APIs to strengthen partner ecosystems. Led customer migration initiatives and developed new product opportunities with major financial institutions.',
    logo: '/img/Experian_logo.svg.png'
  },
  {
    role: 'Director - Product & Strategy',
    company: 'Optima Tax Relief',
    period: '2017 – 2019',
    description: 'Managed the development and rollout of CRM systems and analytics infrastructure driving significant growth. Introduced recurring revenue product lines and implemented strategic planning processes that improved cross-functional alignment.',
    logo: '/img/optima-tax-logo.png'
  },
  {
    role: 'Marketing Automation Lead',
    company: 'LA Fitness',
    period: '2015 – 2017',
    description: 'Designed CRM-based marketing automation that significantly improved member retention and campaign effectiveness. Managed email campaigns reaching millions of members and optimized conversion funnels across digital touchpoints.',
    logo: '/img/LA_Fitness_logo.svg.png'
  },
];

const projects = [
  {
    title: 'Protection Plan Launch',
    description: 'Drove the end-to-end development of a recurring revenue product offering audit defense, identity protection, and IRS notice support. Managed cross-functional teams through ideation, prototyping, compliance review, and launch. The product was on pace to generate over $1M in its first year with a churn rate under 2.5%.',
    technologies: ['Revenue Generation', 'Product Development', 'Compliance'],
    company: 'Optima Tax',
    logo: '/img/optima-tax-logo.png'
  },
  {
    title: 'Client Portal Redesign',
    description: 'Led a full redesign of the client-facing portal, collaborating with engineering and servicing teams. Focused on usability and retention, which led to a 56% increase in return visits and a measurable improvement in client satisfaction scores.',
    technologies: ['UX Design', 'Client Retention', 'Satisfaction Metrics'],
    company: 'Optima Tax',
    logo: '/img/optima-tax-logo.png'
  },
  {
    title: 'OneSearch',
    description: 'Developed advanced search and match technology utilizing predictive matching and artificial intelligence to evaluate identity elements. Optimized system performance by reducing search response times by 80% while enhancing match accuracy for better risk assessment and compliance management.',
    technologies: ['AI/ML', 'Identity Verification', 'Performance Optimization'],
    company: 'Experian',
    logo: '/img/Experian_logo.svg.png'
  },
  {
    title: 'Experian DataShare',
    description: 'Led development of a platform that solves the outdated and manual process of gathering, sharing, and analyzing financial statements. Streamlined the lending journey for SMBs by enabling real-time analysis of client liquidity and financial data, integrating with over 100 accounting packages to deliver instant credit intelligence.',
    technologies: ['Financial Data Integration', 'Lending Automation', 'Risk Management'],
    company: 'Experian',
    logo: '/img/Experian_logo.svg.png'
  },
  {
    title: 'Invoice Fast Trak',
    description: 'Led product development for an innovative invoice financing platform enabling small and diverse-owned businesses to sell unpaid invoices for immediate cash flow. Integrated with major accounting software to automate verification and enhanced the underwriting process to improve funding speed and approval rates.',
    technologies: ['Fintech', 'Invoice Financing', 'Financial Inclusion'],
    company: 'Crowdz',
    logo: '/img/Crowdz.png'
  },
  {
    title: 'We Want You Back',
    description: 'Designed, executed, and managed 20+ email campaigns to a database of over 3 million members. Optimized templates, targeting, and testing processes—ultimately increasing conversion rates by 500%.',
    technologies: ['Email Marketing', 'A/B Testing', 'Conversion Optimization'],
    company: 'LA Fitness',
    logo: '/img/LA_Fitness_logo.svg.png'
  },
  {
    title: 'mPulse Health Experience and Insights',
    description: 'A configurable suite of products designed to optimize the end-to-end health journey. Combines predictive analytics, omnichannel engagement, and health portals to enhance member experiences and outcomes.',
    technologies: ['Healthcare Tech', 'Analytics', 'Patient Engagement'],
    company: 'mPulse Mobile',
    logo: '/img/Logo-mPulse.svg'
  },
  {
    title: 'mPulse One',
    description: 'An integrated platform bringing together various health engagement solutions, enabling healthcare organizations to deliver personalized and scalable communication strategies.',
    technologies: ['Healthcare', 'Automation', 'Data Analytics'],
    company: 'mPulse Mobile',
    logo: '/img/Logo-mPulse.svg'
  },
];

const AboutPage: React.FC = () => {
  const [expandedExp, setExpandedExp] = useState<number | null>(0);
  const [currentPage, setCurrentPage] = useState(0);

  const projectsPerPage = 2;
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.6 }
    }
  };

  // Get company color for styling
  const getCompanyColor = (company: string) => {
    const companyColors = {
      'Optima Tax': 'from-blue-500 to-cyan-400',
      'Experian': 'from-red-500 to-red-400',
      'LA Fitness': 'from-purple-500 to-fuchsia-400',
      'mPulse Mobile': 'from-teal-500 to-emerald-400',
      'Crowdz': 'from-indigo-500 to-blue-400',
    };
    
    return companyColors[company as keyof typeof companyColors] || 'from-blue-500 to-indigo-400';
  };

  return (
    <div className="min-h-screen bg-slate-900 text-slate-50 relative overflow-x-hidden">
      <FixedBackground />
      <Header />
      
      <section className="pt-28 pb-20 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-16"
          >
            <div className="inline-block">
              <motion.div
                className="h-1 w-20 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full mb-4 mx-auto"
                initial={{ width: 0 }}
                animate={{ width: 80 }}
                transition={{ duration: 0.8 }}
              />
              <h2 className="text-4xl font-bold text-white dark:text-white mb-4">
                About <span className="text-yellow-400" style={{ textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)' }}>Me</span>
              </h2>
            </div>
            <p className="text-xl text-white/80 dark:text-white/80 max-w-3xl mx-auto leading-relaxed">
              I specialize in designing scalable, compliant digital engagement solutions trusted by Fortune 500 companies and rapidly growing startups. With experience spanning healthcare, fintech, and SaaS, I focus on building practical solutions that deliver measurable outcomes.
            </p>
          </motion.div>

          <motion.div 
            className="space-y-8 max-w-4xl mx-auto"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div variants={itemVariants}>
              <div className="bg-white/10 dark:bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-lg border border-white/20">
                <div className="flex items-center mb-6">
                  <div className="w-2 h-8 bg-gradient-to-b from-blue-500 to-indigo-600 rounded-full mr-4"></div>
                  <h3 className="text-2xl font-semibold text-white dark:text-white">Professional Journey</h3>
                </div>
                <div className="space-y-4">
                  {experience.map((exp, index) => (
                    <motion.div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => setExpandedExp(expandedExp === index ? null : index)}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.99 }}
                      transition={{ duration: 0.1 }}
                    >
                      <div className="flex justify-between items-center bg-white/10 dark:bg-white/5 hover:bg-white/15 dark:hover:bg-white/10 p-4 rounded-xl border border-white/10 transition-all duration-150">
                        <div className="flex items-center gap-4">
                          {exp.logo && (
                            <div className={`flex-shrink-0 w-12 h-12 rounded-full ${exp.company === 'Optima Tax Relief' ? 'bg-blue-600' : 'bg-white'} flex items-center justify-center shadow-lg overflow-hidden`}>
                              <img 
                                src={exp.logo} 
                                alt={`${exp.company} logo`} 
                                className="w-10 h-10 object-contain" 
                              />
                            </div>
                          )}
                          <div>
                            <h4 className="font-semibold text-white dark:text-white text-lg">
                              {exp.role}
                            </h4>
                            <p className="text-blue-300 dark:text-blue-300">{exp.company}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <span className="text-sm text-white/70 dark:text-white/70 mr-3">{exp.period}</span>
                          <motion.div
                            animate={{ rotate: expandedExp === index ? 180 : 0 }}
                            transition={{ duration: 0.15 }}
                          >
                            <svg className="w-5 h-5 text-white/70 dark:text-white/70" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                          </motion.div>
                        </div>
                      </div>
                      <AnimatePresence>
                        {expandedExp === index && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.15 }}
                            className={`bg-gradient-to-r from-${getCompanyColor(exp.company).split(' ')[0]}/20 to-${getCompanyColor(exp.company).split(' ')[1]}/10 border-l-2 border-${getCompanyColor(exp.company).split(' ')[0]} mt-1 pl-4 py-3 rounded-r-xl`}
                          >
                            <p className="text-white/80 dark:text-white/80">{exp.description}</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>

            <motion.div variants={itemVariants}>
              <div className="bg-white/10 dark:bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-lg border border-white/20">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <div className="w-2 h-8 bg-gradient-to-b from-blue-500 to-teal-500 rounded-full mr-4"></div>
                    <h3 className="text-2xl font-semibold text-white dark:text-white">Key Projects</h3>
                  </div>
                  <div className="flex space-x-2">
                    <button 
                      onClick={prevPage}
                      className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-150"
                      disabled={currentPage === 0}
                    >
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                    <button 
                      onClick={nextPage}
                      className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-150"
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {projects.slice(currentPage * projectsPerPage, (currentPage + 1) * projectsPerPage).map((project, index) => (
                    <motion.div 
                      key={index} 
                      className="bg-white/10 rounded-xl p-6 border border-white/10 hover:border-white/20 transition-colors duration-150 flex flex-col"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                    >
                      <div className="flex items-center mb-3">
                        {project.logo && (
                          <div className={`flex-shrink-0 w-10 h-10 rounded-full ${project.company === 'Optima Tax' ? 'bg-blue-600' : 'bg-white'} flex items-center justify-center shadow-lg overflow-hidden mr-3`}>
                            <img 
                              src={project.logo} 
                              alt={`${project.company} logo`} 
                              className="w-8 h-8 object-contain" 
                            />
                          </div>
                        )}
                        <h4 className="font-semibold text-white text-lg">{project.title}</h4>
                      </div>
                      <p className="text-white/70 mb-4 flex-grow text-sm">{project.description}</p>
                      <div className="mt-2">
                        <div className="flex flex-wrap gap-2">
                          {project.technologies.map((tech, i) => (
                            <span 
                              key={i} 
                              className={`inline-block px-2 py-1 text-xs rounded-full bg-${getCompanyColor(project.company).split(' ')[0]}/20 text-${getCompanyColor(project.company).split(' ')[0]}/90 border border-${getCompanyColor(project.company).split(' ')[0]}/30`}
                            >
                              {tech}
                            </span>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="flex justify-center mt-6">
                  <div className="flex space-x-2">
                    {Array.from({ length: totalPages }).map((_, i) => (
                      <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={`w-2.5 h-2.5 rounded-full ${
                          i === currentPage ? 'bg-blue-500' : 'bg-white/30'
                        }`}
                        aria-label={`Go to page ${i + 1}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div variants={itemVariants}>
              <div className="bg-white/10 dark:bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-lg border border-white/20">
                <div className="flex items-center mb-6">
                  <div className="w-2 h-8 bg-gradient-to-b from-indigo-500 to-purple-600 rounded-full mr-4"></div>
                  <h3 className="text-2xl font-semibold text-white dark:text-white">Education</h3>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  {education.map((edu, index) => (
                    <motion.div 
                      key={index} 
                      className="bg-white/10 p-6 rounded-xl border border-white/10 hover:bg-white/15 transition-colors duration-150"
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.99 }}
                      transition={{ duration: 0.1 }}
                    >
                      <div className="flex flex-col md:flex-row md:items-center gap-4">
                        <div className={`flex-shrink-0 w-14 h-14 rounded-full bg-white flex items-center justify-center shadow-lg overflow-hidden`}>
                          <img 
                            src={edu.logo} 
                            alt={`${edu.school} logo`} 
                            className="w-12 h-12 object-contain" 
                          />
                        </div>
                        <div className="flex-grow">
                          <h4 className="font-bold text-white text-xl">{edu.school}</h4>
                          <p className={`text-lg font-medium bg-gradient-to-r ${edu.color} bg-clip-text text-transparent`}>{edu.degree}</p>
                          <div className="flex items-center mt-1">
                            <svg className="w-4 h-4 text-white/70 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <p className="text-sm text-white/70">{edu.period}</p>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>

            <motion.div variants={itemVariants}>
              <div className="bg-white/10 dark:bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-lg border border-white/20">
                <div className="flex items-center mb-6">
                  <div className="w-2 h-8 bg-gradient-to-b from-green-500 to-emerald-400 rounded-full mr-4"></div>
                  <h3 className="text-2xl font-semibold text-white dark:text-white">Outside the Office</h3>
                </div>
                <div className="space-y-4 text-white/80">
                  <motion.p 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="leading-relaxed"
                  >
                    When I'm not building products or working through engagement challenges, I'm usually learning something new about technology, behavior, or data. I'm drawn to how people interact with digital tools—and how thoughtful design and clear signals can drive real outcomes.
                  </motion.p>
                  
                  <motion.p 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="leading-relaxed"
                  >
                    I enjoy digging into the details, asking questions, and trying to make things just a little bit better. Whether it's understanding a new dataset or refining a user flow, I've always loved the craft of product.
                  </motion.p>
                  
                  <motion.p 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="leading-relaxed"
                  >
                    Outside of work, I unplug with my family and try to stay curious. The best ideas usually come when I'm not chasing them.
                  </motion.p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>
      
      <footer className="border-t border-white/10 mt-20 pt-8 pb-16 text-white/60 text-center text-sm">
        <div className="max-w-4xl mx-auto pb-8 px-4">
          <span className="text-white">© {new Date().getFullYear()}</span> <span className="text-white font-medium">Kyle Blanchard</span> · 
          <span className="ml-2 inline-flex items-center">
            <span className="text-yellow-400 font-bold" style={{ 
              textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)'
            }}>Designed by AI</span>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage; 