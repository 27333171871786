import React from 'react';
import { motion } from 'framer-motion';

interface ErrorBannerProps {
  message: string;
  onRetry?: () => void;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ message, onRetry }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{
        type: "spring",
        stiffness: 500,
        damping: 20,
        mass: 0.5,
        duration: 0.2
      }}
      className="mb-4 bg-red-500/20 border border-red-500 rounded-lg p-3 flex items-start"
    >
      <motion.div 
        className="flex-shrink-0 text-red-500 mr-2 mt-0.5"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.15, delay: 0.05 }}
      >
        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
        </svg>
      </motion.div>
      <motion.div 
        className="flex-1 text-left"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, delay: 0.1 }}
      >
        <p className="text-sm text-red-400">{message}</p>
      </motion.div>
      {onRetry && (
        <motion.button
          type="button"
          onClick={onRetry}
          className="ml-4 inline-flex text-xs font-medium text-red-400 hover:text-red-300 underline focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 rounded"
          whileHover={{ scale: 1.05, transition: { duration: 0.1 } }}
          whileTap={{ scale: 0.95, transition: { duration: 0.05 } }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.15 }}
        >
          Try again
        </motion.button>
      )}
    </motion.div>
  );
};

export default ErrorBanner; 