import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SuccessMessage from './SuccessMessage';
import ErrorBanner from './ErrorBanner';

interface ContactFormProps {
  onClose: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [hasInteracted, setHasInteracted] = useState(false);
  const [helpOption, setHelpOption] = useState('');
  const emailInputRef = useRef<HTMLInputElement>(null);
  
  // Form state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  
  const helpOptions = [
    { value: 'engagement-consulting', label: '🧠 Engagement Consulting' },
    { value: 'marketing-automation', label: '⚙️ Marketing Automation' },
    { value: 'tcpa-compliance', label: '🛡️ TCPA Compliance' },
    { value: 'engagement-data', label: '📊 Engagement Data Consulting' },
    { value: 'product-coaching', label: '🚀 Product Coaching' },
    { value: 'experimentation', label: '🧪 Experimentation & Testing' },
    { value: 'channel-engagement', label: '📢 Channel Engagement Strategy' },
    { value: 'other', label: 'Other' }
  ];
  
  // Validate email format
  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  
  // Handle email input validation
  const handleEmailBlur = () => {
    if (!hasInteracted) return;
    
    if (!email) {
      setEmailError('Email is required');
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };
  
  // Handle message input validation
  const handleMessageBlur = () => {
    if (!hasInteracted) return;
    
    if (!message) {
      setMessageError('Message is required');
    } else if (message.length > 500) {
      setMessageError('Message must be less than 500 characters');
    } else {
      setMessageError('');
    }
  };

  // Handle input changes
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setHasInteracted(true);
    if (emailError) {
      handleEmailBlur();
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    setHasInteracted(true);
    if (messageError) {
      handleMessageBlur();
    }
  };
  
  // Check if form is valid
  const isFormValid = email && message && !emailError && !messageError;
  
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Only validate on submit if user hasn't interacted yet
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    
    // Validate form before submission
    handleEmailBlur();
    handleMessageBlur();
    
    if (!isFormValid) return;
    
    setIsSubmitting(true);
    setSubmitError('');
    
    try {
      const response = await fetch('https://formspree.io/f/xldjobzr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message }),
      });
      
      if (response.ok) {
        setSubmitSuccess(true);
        setEmail('');
        setMessage('');
      } else {
        setSubmitError('Oops! Something went wrong. Please try again.');
      }
    } catch (error) {
      setSubmitError('Network error. Please check your connection and try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Reset form and reset success/error state
  const handleRetry = () => {
    setSubmitError('');
  };
  
  // Animation variants for form elements
  const formElementVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 25,
        mass: 0.5,
        delay: custom * 0.05
      }
    })
  };
  
  // Focus the email input when the form mounts
  useEffect(() => {
    if (emailInputRef.current) {
      // Small delay to ensure the modal is fully mounted
      setTimeout(() => {
        emailInputRef.current?.focus();
      }, 100);
    }
  }, []);
  
  return (
    <div>
      {submitSuccess ? (
        <SuccessMessage onClose={onClose} />
      ) : (
        <>
          <motion.div 
            className="text-center mb-6"
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <h2 className="text-2xl font-bold text-white mb-2">
              Let's Talk! 💬
            </h2>
            <p className="text-white/70">
              Send me a message and I'll get back to you within 24 hours.
            </p>
          </motion.div>
          
          <AnimatePresence>
            {submitError && <ErrorBanner message={submitError} onRetry={handleRetry} />}
          </AnimatePresence>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <motion.div
              custom={0}
              variants={formElementVariants}
              initial="hidden"
              animate="visible"
            >
              <label htmlFor="email" className="block text-sm font-medium text-white/90 mb-1">
                Email Address
              </label>
              <input
                ref={emailInputRef}
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                className={`w-full bg-slate-700/50 border ${
                  emailError ? 'border-red-500' : 'border-slate-600'
                } rounded-lg px-4 py-3 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-150`}
                placeholder="you@example.com"
                required
                disabled={isSubmitting}
              />
              <AnimatePresence>
                {emailError && (
                  <motion.p
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -5, transition: { duration: 0.1 } }}
                    transition={{ duration: 0.15 }}
                    className="mt-1 text-sm text-red-400"
                  >
                    {emailError}
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.div>
            
            <motion.div
              custom={1}
              variants={formElementVariants}
              initial="hidden"
              animate="visible"
            >
              <label htmlFor="help-option" className="block text-sm font-medium text-white/90 mb-1">
                How I Can Help
              </label>
              <select
                id="help-option"
                value={helpOption}
                onChange={(e) => setHelpOption(e.target.value)}
                className="w-full bg-slate-700/50 border border-slate-600 rounded-lg px-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-150"
                disabled={isSubmitting}
              >
                <option value="">Select an option</option>
                {helpOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </motion.div>
            
            <motion.div
              custom={2}
              variants={formElementVariants}
              initial="hidden"
              animate="visible"
            >
              <label htmlFor="message" className="block text-sm font-medium text-white/90 mb-1">
                Message
              </label>
              <textarea
                id="message"
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageBlur}
                className={`w-full bg-slate-700/50 border ${
                  messageError ? 'border-red-500' : 'border-slate-600'
                } rounded-lg px-4 py-3 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none transition-colors duration-150`}
                placeholder="How can I help you?"
                rows={5}
                required
                maxLength={500}
                disabled={isSubmitting}
              ></textarea>
              <div className="flex justify-between items-center mt-1">
                <AnimatePresence>
                  {messageError ? (
                    <motion.p
                      initial={{ opacity: 0, y: -5 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -5, transition: { duration: 0.1 } }}
                      transition={{ duration: 0.15 }}
                      className="text-sm text-red-400"
                    >
                      {messageError}
                    </motion.p>
                  ) : (
                    <span className="text-sm text-white/50"></span>
                  )}
                </AnimatePresence>
                <span className={`text-xs ${
                  message.length > 450 ? (message.length > 500 ? 'text-red-400' : 'text-yellow-400') : 'text-white/50'
                }`}>
                  {message.length}/500
                </span>
              </div>
            </motion.div>
            
            <motion.button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-bold py-3 px-6 rounded-lg mt-4 flex justify-center items-center shadow-lg hover:shadow-blue-500/20"
              disabled={!isFormValid || isSubmitting}
              whileHover={isFormValid && !isSubmitting ? { scale: 1.02, transition: { duration: 0.15 } } : {}}
              whileTap={isFormValid && !isSubmitting ? { scale: 0.98, transition: { duration: 0.1 } } : {}}
              initial={{ opacity: 0, y: 10 }}
              animate={{ 
                opacity: isFormValid ? 1 : 0.7, 
                y: 0,
                transition: {
                  type: "spring",
                  stiffness: 500,
                  damping: 25,
                  mass: 0.5,
                  delay: 0.1
                }
              }}
            >
              {isSubmitting ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </div>
              ) : (
                "Send Message"
              )}
            </motion.button>
          </form>
        </>
      )}
    </div>
  );
};

export default ContactForm; 