import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ContactModal from './ContactModal';

const Contact: React.FC = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.6 }
    }
  };

  // Function to open contact modal
  const openContactModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsContactModalOpen(true);
    // Prevent body scrolling when modal is open
    document.body.style.overflow = 'hidden';
  };

  // Function to close contact modal
  const closeContactModal = () => {
    setIsContactModalOpen(false);
    // Restore body scrolling when modal is closed
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <section 
        id="contact" 
        className="pt-8 pb-20 relative overflow-hidden dark:bg-gray-900"
      >
        {/* Background elements removed - now handled by FixedBackground in App.tsx */}
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <div className="inline-block">
              <motion.div
                className="h-1 w-20 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full mb-4 mx-auto"
                initial={{ width: 0 }}
                whileInView={{ width: 80 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              />
              <h2 className="text-4xl font-bold text-white dark:text-white mb-4">
                Let's <span className="text-yellow-400" style={{ textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)' }}>Talk</span>
              </h2>
            </div>
            <p className="text-xl text-white/80 dark:text-white/80 max-w-3xl mx-auto leading-relaxed">
              I'm ready to discuss how we can drive meaningful growth for your digital engagement initiatives.
            </p>
          </motion.div>

          <div className="max-w-3xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="bg-white/10 dark:bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-lg border border-white/20"
            >
              <div className="space-y-6">
                <button 
                  onClick={openContactModal}
                  className="block w-full text-left"
                >
                  <motion.div 
                    className="flex flex-col md:flex-row md:items-center gap-6 p-4 bg-white/10 dark:bg-white/5 rounded-xl border border-white/10 hover:bg-white/15 dark:hover:bg-white/10 transition-colors duration-150 cursor-pointer"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full flex items-center justify-center shrink-0 mx-auto md:mx-0">
                      <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                    </div>
                    <div className="text-center md:text-left">
                      <h3 className="text-xl font-semibold text-white dark:text-white mb-1">
                        Email Me
                      </h3>
                      <p className="text-blue-300 font-medium text-lg">
                        Use the contact form for a response within 24 hours
                      </p>
                    </div>
                  </motion.div>
                </button>

                <a 
                  href="https://linkedin.com/in/kyle-blanchard"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block w-full"
                >
                  <motion.div 
                    className="flex flex-col md:flex-row md:items-center gap-6 p-4 bg-white/10 dark:bg-white/5 rounded-xl border border-white/10 hover:bg-white/15 dark:hover:bg-white/10 transition-colors duration-150 cursor-pointer"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-pink-600 rounded-full flex items-center justify-center shrink-0 mx-auto md:mx-0">
                      <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
                      </svg>
                    </div>
                    <div className="text-center md:text-left">
                      <h3 className="text-xl font-semibold text-white dark:text-white mb-1">
                        Connect on LinkedIn
                      </h3>
                      <p className="text-purple-300 font-medium text-lg">
                        linkedin.com/in/kyle-blanchard
                      </p>
                    </div>
                  </motion.div>
                </a>

                <motion.div
                  className="mt-8 flex justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                >
                  <motion.button 
                    onClick={openContactModal}
                    className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-500 to-indigo-600 px-10 py-4 rounded-full text-xl font-bold text-white transition-colors duration-150"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ duration: 0.1 }}
                  >
                    <span>Contact Me</span>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </motion.button>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
        
        {/* Removed the bottom gradient since it's now consistent with the fixed background */}
      </section>
      
      {/* Contact Modal */}
      <AnimatePresence>
        {isContactModalOpen && (
          <ContactModal 
            isOpen={isContactModalOpen} 
            onClose={closeContactModal} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Contact; 