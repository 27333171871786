import React, { useState, useEffect, useCallback, memo } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Contact from './components/Contact';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import AboutPage from './components/AboutPage';
import { FixedBackground } from './components/index';

// Memoized main layout component
const MainLayout = memo(() => {
  // Add scroll progress tracking with throttling for better performance
  const [scrollProgress, setScrollProgress] = useState(0);
  const location = useLocation();
  
  // Throttled scroll handler for better performance
  const handleScroll = useCallback(() => {
    // Use requestAnimationFrame for smooth animation
    window.requestAnimationFrame(() => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    });
  }, []);
  
  useEffect(() => {
    // Add passive listener for better performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Handle scrolling to sections based on URL hash
  useEffect(() => {
    // Check if there's a hash in the URL (e.g. /#services)
    if (location.hash) {
      // Give the DOM time to render before scrolling
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    } else {
      // If no hash, scroll to top
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-slate-900 text-slate-50 relative pb-20 overflow-x-hidden">
      {/* Scroll Progress Indicator - optimized for performance */}
      <div className="fixed top-0 left-0 right-0 h-1 z-50 pointer-events-none">
        <div 
          className="h-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
          style={{ 
            width: `${scrollProgress}%`,
            transform: 'translateZ(0)', // Hardware acceleration
            transition: 'width 0.1s ease-out',
            boxShadow: '0 0 10px rgba(139, 92, 246, 0.5), 0 0 20px rgba(139, 92, 246, 0.3)'
          }}
        />
      </div>
      
      <FixedBackground />
      <Header />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 md:px-8">
        <Hero />
      </div>
      
      {/* Services section with wider container */}
      <div id="services" className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <Services />
      </div>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 md:px-8">
        <Contact />
      </div>
      
      <footer className="border-t border-white/10 mt-20 pt-8 pb-16 text-white/60 text-center text-sm">
        <div className="max-w-4xl mx-auto pb-8 px-4">
          <span className="text-white">© {new Date().getFullYear()}</span> <span className="text-white font-medium">Kyle Blanchard</span> · 
          <span className="ml-2 inline-flex items-center">
            <span className="text-yellow-400 font-bold" style={{ 
              textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)'
            }}>Designed by AI</span>
          </span>
        </div>
      </footer>
    </div>
  );
});

// Blog layout component
const BlogLayout = memo(() => {
  return (
    <div className="min-h-screen bg-slate-900 text-slate-50 relative overflow-x-hidden">
      <FixedBackground />
      <Header />
      
      <Routes>
        <Route path="/" element={<BlogList />} />
        <Route path="/:slug" element={<BlogPost />} />
      </Routes>
      
      <footer className="border-t border-white/10 mt-20 pt-8 pb-16 text-white/60 text-center text-sm">
        <div className="max-w-4xl mx-auto pb-8 px-4">
          <span className="text-white">© {new Date().getFullYear()}</span> <span className="text-white font-medium">Kyle Blanchard</span> · 
          <span className="ml-2 inline-flex items-center">
            <span className="text-yellow-400 font-bold" style={{ 
              textShadow: '0 0 7px rgba(250, 204, 21, 0.7), 0 0 12px rgba(250, 204, 21, 0.3), 0 0 18px rgba(250, 204, 21, 0.1)'
            }}>Designed by AI</span>
          </span>
        </div>
      </footer>
    </div>
  );
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/blog/*" element={<BlogLayout />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;
