// This file provides browser-compatible functions to access blog posts
// since direct filesystem access isn't available in the browser

// Import generated posts
// This file is created by the build script
import generatedPosts from './generatedPosts.json';

// Define the post data structure
export interface PostMeta {
  slug: string;
  title: string;
  date: string;
  description: string;
  tags?: string[]; // Add tags array
}

export interface Post extends PostMeta {
  content: string;
}

// Convert imported JSON to Post array
const posts: Post[] = generatedPosts as Post[];

// Function to get all post metadata
export function getAllPosts(): PostMeta[] {
  return posts.map(({ content, ...meta }) => meta);
}

// Function to get a post by slug
export function getPost(slug: string): Post | null {
  return posts.find(post => post.slug === slug) || null;
}

// Function to get sorted posts by date (newest first)
export function getSortedPosts(): PostMeta[] {
  return getAllPosts().sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA;
  });
} 