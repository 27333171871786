import React, { useEffect, useState, useCallback } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import ContactModal from './ContactModal';

const Hero: React.FC = () => {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 400], [1, 0]);
  const y = useTransform(scrollY, [0, 400], [0, 100]);
  const scale = useTransform(scrollY, [0, 400], [1, 0.9]);
  const scrollIndicatorOpacity = useTransform(scrollY, [0, 100], [0.7, 0]);
  const overlayOpacity = useTransform(scrollY, [0, 200], [0.7, 0]);
  const [mounted, setMounted] = useState(false);
  const [neonOn, setNeonOn] = useState(false);
  const [neonFlickerState, setNeonFlickerState] = useState(0);
  const [isToggling, setIsToggling] = useState(false);
  // State for contact modal
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // Function to open contact modal
  const openContactModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsContactModalOpen(true);
    // Prevent body scrolling when modal is open
    document.body.style.overflow = 'hidden';
  };

  // Function to close contact modal
  const closeContactModal = () => {
    setIsContactModalOpen(false);
    // Restore body scrolling when modal is closed
    document.body.style.overflow = 'auto';
  };

  // Function to handle the neon sign toggle
  const toggleNeonSign = useCallback(() => {
    if (isToggling) return; // Prevent multiple toggles while animation is in progress
    
    setIsToggling(true);
    
    if (neonOn) {
      // Turn off immediately
      setNeonOn(false);
      setNeonFlickerState(0);
      setTimeout(() => setIsToggling(false), 300);
    } else {
      // Start the flicker sequence
      runFlickerSequence().then(() => {
        setIsToggling(false);
      });
    }
  }, [neonOn, isToggling]);

  // Extracted the flicker sequence into its own function so we can reuse it
  const runFlickerSequence = async () => {
    // Initial delay
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // First attempt to turn on
    setNeonOn(true);
    setNeonFlickerState(1);
    await new Promise(resolve => setTimeout(resolve, 200));
    
    // First flicker - dim
    setNeonFlickerState(2);
    await new Promise(resolve => setTimeout(resolve, 120));
    
    // Brief off
    setNeonOn(false);
    setNeonFlickerState(0);
    await new Promise(resolve => setTimeout(resolve, 180));
    
    // Second attempt - multiple rapid flickers
    setNeonOn(true);
    setNeonFlickerState(1);
    await new Promise(resolve => setTimeout(resolve, 80));
    setNeonFlickerState(3);
    await new Promise(resolve => setTimeout(resolve, 50));
    setNeonFlickerState(2);
    await new Promise(resolve => setTimeout(resolve, 90));
    
    // Brief off again
    setNeonOn(false);
    setNeonFlickerState(0);
    await new Promise(resolve => setTimeout(resolve, 120));
    
    // Final turn on with subtle flicker
    setNeonOn(true);
    setNeonFlickerState(2);
    await new Promise(resolve => setTimeout(resolve, 100));
    setNeonFlickerState(3);
    await new Promise(resolve => setTimeout(resolve, 150));
    
    // Stable on state
    setNeonFlickerState(4);
  };

  useEffect(() => {
    setMounted(true);
    
    // Add flicker animation styles with reduced flickering
    const style = document.createElement('style');
    style.textContent = `
      @keyframes flicker {
        0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
          opacity: 1;
          text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 20px #facc15, 0 0 30px #facc15, 0 0 40px #facc15;
        }
        20%, 22%, 24%, 55% {
          opacity: 0.8;
          text-shadow: 0 0 5px #fff, 0 0 8px #fff, 0 0 15px #facc15, 0 0 25px #facc15, 0 0 30px #facc15;
        }
      }
      
      .animate-flicker {
        animation: flicker 8s linear infinite;
      }
    `;
    document.head.appendChild(style);
    
    // Run the flicker sequence on load
    runFlickerSequence();
    
    return () => {
      // Clean up the injected style when component unmounts
      if (style.parentNode) {
        document.head.removeChild(style);
      }
    };
  }, []);

  if (!mounted) return null;

  const getNeonShadow = () => {
    // Different shadow intensities based on flicker state
    switch(neonFlickerState) {
      case 1: // Initial turn on - dim
        return `0 0 2px #fff, 0 0 4px #fff, 0 0 8px #facc15, 0 0 12px #facc15`;
      case 2: // Dimmer flicker
        return `0 0 1px #fff, 0 0 2px #fff, 0 0 4px #facc15, 0 0 6px #facc15`;
      case 3: // Brighter flicker
        return `0 0 3px #fff, 0 0 6px #fff, 0 0 12px #facc15, 0 0 18px #facc15`;
      case 4: // Stable on - enhanced neon glow
        return `0 0 4px #fff, 0 0 8px #fff, 0 0 12px #facc15, 0 0 25px #facc15, 0 0 40px #facc15`;
      default:
        return 'none';
    }
  };

  return (
    <>
      <section className="relative min-h-[105vh] flex items-center justify-center text-center overflow-hidden pt-12 md:pt-0" style={{ marginBottom: '-40px' }}>
        {/* Removing spotlight gradients for cleaner background */}
        
        <motion.div 
          style={{ opacity, y, scale }}
          className="relative z-10 max-w-3xl mx-auto px-4 sm:px-6 mb-8 md:mb-16 w-full"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="mb-8 md:mb-14"
          >
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="font-bold mb-8 md:mb-12 leading-tight"
            >
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="text-3xl sm:text-4xl md:text-6xl text-white"
                style={{
                  textShadow: '0 4px 20px rgba(0,0,0,0.5)',
                  display: 'block',
                  lineHeight: '1.1',
                  paddingBottom: '12px',
                  fontWeight: 600,
                  letterSpacing: '-0.01em',
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Digital Engagement
              </motion.span>
              <motion.div
                className="relative inline-block mt-[-20px] md:mt-[-40px]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0, rotate: -3 }}
                transition={{ 
                  opacity: { duration: 0.8, delay: 0.2 },
                  y: { duration: 0.5, delay: 0.2 },
                  rotate: { duration: 0.8, delay: 0.4 }
                }}
                style={{ 
                  display: 'block',
                  width: 'auto',
                  maxWidth: '130%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  transform: 'perspective(800px) rotateX(5deg) translateX(-5%)',
                  transformOrigin: 'center bottom',
                  zIndex: 5,
                  cursor: 'pointer'
                }}
                onClick={toggleNeonSign}
                whileHover={{ 
                  scale: 1.03, 
                  transition: { duration: 0.2 } 
                }}
                whileTap={{ scale: 0.98 }}
              >
                {/* Power button indicator */}
                <motion.div 
                  className="absolute top-0 right-0 transform translate-x-0 sm:translate-x-6 -translate-y-2 bg-gray-800 rounded-full p-2 shadow-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
                  style={{
                    zIndex: 10,
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.36 6.64C19.6 7.88 20.38 9.66 20.38 11.66C20.38 17.3 14.69 21.1 9.54 19.07C8.8 18.8 8.15 18.44 7.54 18C2.42 14.58 2.42 8.74 7.54 5.32C8.15 4.88 8.8 4.52 9.54 4.25C10.37 3.95 11.09 3.87 12 3.87"
                      stroke={neonOn ? "#FACC15" : "#888888"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 12V2"
                      stroke={neonOn ? "#FACC15" : "#888888"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </motion.div>
                
                <AnimatePresence>
                  <motion.div 
                    className="text-[3.6rem] sm:text-[5rem] md:text-[7.8rem] text-center"
                    style={{
                      color: neonOn ? '#facc15' : '#222',
                      fontFamily: "'Tilt Neon', sans-serif",
                      fontWeight: 400,
                      position: 'relative',
                      marginBottom: '0.5rem',
                      filter: neonOn ? 'drop-shadow(0 0 25px rgba(250, 204, 21, 0.65)) blur(0.3px)' : 'none',
                      letterSpacing: '-0.02em',
                      textTransform: 'uppercase',
                      WebkitTextStroke: neonOn ? '1px rgba(250, 204, 21, 0.9)' : 'none',
                      transition: 'color 0.3s ease',
                      lineHeight: '1',
                      padding: '0 5px'
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {neonOn ? (
                      <span 
                        className="inline-block animate-flicker"
                        style={{
                          background: 'linear-gradient(to bottom, rgba(250, 204, 21, 1) 0%, rgba(250, 204, 21, 0.9) 50%, rgba(250, 204, 21, 1) 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          display: 'inline-block',
                          position: 'relative',
                          padding: '0 5px'
                        }}
                      >
                        SIMPLIFIED.
                      </span>
                    ) : (
                      <span className="opacity-70">SIMPLIFIED.</span>
                    )}
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="text-lg sm:text-xl md:text-2xl text-white/90 mb-8 md:mb-10 leading-relaxed mx-auto px-3"
              style={{ 
                maxWidth: '580px', 
                letterSpacing: '0.01em' 
              }}
            >
              Empowering organizations to streamline digital engagement with intelligent automation, practical compliance, and measurable growth. I turn complexity into clarity—and results.
            </motion.p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-col items-center justify-center mb-2"
          >
            <div className="flex flex-col items-center w-full max-w-md px-3 sm:px-0">
              <motion.button 
                onClick={openContactModal}
                className="group relative inline-flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500/90 to-indigo-600/90 w-full px-6 sm:px-10 py-3 sm:py-4 rounded-full text-base sm:text-xl font-bold text-white transition-all duration-300 shadow-lg"
                whileHover={{ 
                  scale: 1.02,
                  boxShadow: "0 0 15px 2px rgba(59, 130, 246, 0.7), 0 0 30px 5px rgba(59, 130, 246, 0.3), 0 0 45px 10px rgba(59, 130, 246, 0.15)"
                }}
                whileTap={{ scale: 0.98 }}
              >
                <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-1 relative z-10" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span className="relative z-10">Contact Me</span>
                <svg className="w-5 h-5 sm:w-6 sm:h-6 transition-transform duration-300 group-hover:translate-x-1 relative z-10" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
                <motion.span 
                  className="absolute inset-0 rounded-full overflow-hidden"
                  initial={{ opacity: 0 }}
                  whileHover={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <span className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-700" />
                  <span className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-indigo-500/20 animate-pulse" style={{ animationDuration: '2s' }} />
                </motion.span>
              </motion.button>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="text-xs text-white/70 mt-2"
              >
                Typically responds within 24 hours
              </motion.div>
              
              {/* Neon sign toggle instructions */}
              {/* Removed the instructions text while keeping the functionality intact */}
            </div>
          </motion.div>
        </motion.div>
        
        {/* Simple down arrow that fades on scroll */}
        <motion.div 
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 1,
            delay: 1.5,
          }}
          style={{ opacity: scrollIndicatorOpacity }}
        >
          <motion.svg 
            width="32" 
            height="32" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="text-yellow-400/70"
            animate={{ y: [0, 6, 0] }}
            transition={{ 
              duration: 2, 
              repeat: Infinity,
              repeatType: "loop",
              ease: "easeInOut" 
            }}
          >
            <path 
              d="M12 5L12 19M12 19L19 12M12 19L5 12" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </motion.svg>
        </motion.div>
      </section>
      
      {/* Contact Modal */}
      <AnimatePresence>
        {isContactModalOpen && (
          <ContactModal 
            isOpen={isContactModalOpen} 
            onClose={closeContactModal} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Hero; 