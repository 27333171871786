import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { PostMeta } from '../blog/browserAdapter';

interface BlogCardProps {
  post: PostMeta;
  index: number;
}

const BlogCard: React.FC<BlogCardProps> = ({ post, index }) => {
  // Format the date for display
  // Using T12:00:00Z ensures consistent date interpretation regardless of timezone
  const formattedDate = new Date(`${post.date}T12:00:00Z`).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className="group relative p-6 rounded-xl bg-gradient-to-br from-slate-800/80 to-slate-900/80 backdrop-blur-sm border border-slate-700/40 shadow-md hover:bg-slate-800/90 transition-colors duration-150"
    >
      <Link to={`/blog/${post.slug}`} className="block">
        <div className="mb-2">
          <span className="text-indigo-300 text-sm font-medium">{formattedDate}</span>
        </div>
        <h3 className="text-xl font-bold text-white mb-3">{post.title}</h3>
        <p className="text-white/70 mb-4">{post.description}</p>
        
        {/* Add tags display */}
        {post.tags && post.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-4">
            {post.tags.map(tag => (
              <span 
                key={tag} 
                className="text-xs px-2 py-1 rounded-full bg-indigo-900/50 text-indigo-300 border border-indigo-800/50"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
        
        <div className="flex items-center text-indigo-400 font-medium">
          <span>Read more</span>
          <svg 
            className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-150" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </div>
      </Link>
    </motion.div>
  );
};

export default BlogCard; 