import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal';

const Header: React.FC = () => {
  const navigate = useNavigate();
  // Keep track of hero section for the smooth scroll behaviors
  const [pastHero, setPastHero] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  // State for contact modal
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const headerRef = useRef<HTMLElement>(null);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      // Check if scrolled past the hero section (adjust 500 based on your hero height)
      const isPastHero = window.scrollY > 500;
      
      if (isPastHero !== pastHero) {
        setPastHero(isPastHero);
      }
    };

    if (isHomePage) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [pastHero, isHomePage]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  // Handle keyboard navigation for the header
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isContactModalOpen) {
          closeContactModal();
        } else if (isMobileMenuOpen) {
          setIsMobileMenuOpen(false);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isContactModalOpen, isMobileMenuOpen]);

  // Prevent body scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  // Function to handle scrolling to sections on the homepage
  const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    
    if (!isHomePage) {
      // If not on homepage, navigate to homepage first with the hash
      navigate(`/${id}`);
    } else {
      // If on homepage, just scroll to the section
      const element = document.querySelector(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        // Focus the section for screen readers
        (element as HTMLElement).focus();
        // Update URL without triggering a page reload
        window.history.pushState(null, '', id);
      }
    }
    setIsMobileMenuOpen(false);
  };

  // Function to open contact modal
  const openContactModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    setIsContactModalOpen(true);
    setIsMobileMenuOpen(false);
    // Prevent body scrolling when modal is open
    document.body.style.overflow = 'hidden';
  };

  // Function to close contact modal
  const closeContactModal = () => {
    setIsContactModalOpen(false);
    // Restore body scrolling when modal is closed
    document.body.style.overflow = 'auto';
    // Return focus to the contact button when modal closes
    const contactButton = document.querySelector('[aria-label="Open contact form"]');
    if (contactButton) {
      (contactButton as HTMLElement).focus();
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <motion.header
        ref={headerRef}
        className="fixed top-0 left-0 right-0 z-50 bg-slate-900/70 backdrop-blur-md shadow-lg border-b border-white/10"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        role="banner"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <motion.div
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.1 }}
            >
              <Link
                to="/"
                className="text-xl font-display tracking-tight text-white font-bold focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900 rounded-md"
                aria-label="Home"
              >
                Blanchard
              </Link>
            </motion.div>

            {/* Desktop Navigation */}
            <nav 
              className="hidden md:flex items-center space-x-4"
              role="navigation"
              aria-label="Main navigation"
            >
              <motion.div
                whileHover={{ y: -1 }}
                transition={{ duration: 0.1 }}
              >
                <Link
                  to="/about"
                  className="font-medium text-white/90 hover:text-white px-3 py-2 rounded-md hover:bg-white/5 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                  aria-label="About page"
                >
                  About
                </Link>
              </motion.div>
              
              <motion.div
                whileHover={{ y: -1 }}
                transition={{ duration: 0.1 }}
              >
                <Link
                  to="/#services"
                  className="font-medium text-white/90 hover:text-white px-3 py-2 rounded-md hover:bg-white/5 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                  onClick={e => scrollToSection(e, "#services")}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      scrollToSection(e, "#services");
                    }
                  }}
                  aria-label="Services section"
                >
                  Services
                </Link>
              </motion.div>
              
              <motion.div
                whileHover={{ y: -1 }}
                transition={{ duration: 0.1 }}
              >
                <Link
                  to="/blog"
                  className="font-medium text-white/90 hover:text-white px-3 py-2 rounded-md hover:bg-white/5 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                  aria-label="Blog page"
                >
                  Blog
                </Link>
              </motion.div>
              
              <motion.button
                onClick={openContactModal}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openContactModal(e);
                  }
                }}
                className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-500 to-indigo-600 px-5 py-2 rounded-full text-base font-semibold text-white transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                transition={{ duration: 0.1 }}
                aria-label="Open contact form"
              >
                <span>Let's Talk</span>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </motion.button>
            </nav>

            {/* Mobile Menu Button */}
            <motion.button
              className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
              onClick={toggleMobileMenu}
              aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
              aria-expanded={isMobileMenuOpen}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {isMobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </motion.button>
          </div>
        </div>
      </motion.header>
      
      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            ref={mobileMenuRef}
            className="fixed inset-0 z-40 md:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {/* Backdrop */}
            <motion.div
              className="fixed inset-0 bg-slate-900/80 backdrop-blur-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsMobileMenuOpen(false)}
            />

            {/* Menu Panel */}
            <motion.div
              className="fixed inset-y-0 right-0 w-full max-w-xs bg-slate-900 shadow-xl"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 200 }}
            >
              <div className="flex flex-col h-full">
                <div className="flex items-center justify-between px-4 py-6 border-b border-white/10">
                  <Link
                    to="/"
                    className="text-xl font-display tracking-tight text-white font-bold"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Blanchard
                  </Link>
                  <button
                    className="p-2 rounded-md text-white hover:bg-white/5 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => setIsMobileMenuOpen(false)}
                    aria-label="Close menu"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <nav className="flex-1 px-4 py-6 space-y-4" role="navigation" aria-label="Mobile navigation">
                  <Link
                    to="/about"
                    className="block px-3 py-2 text-base font-medium text-white/90 hover:text-white rounded-md hover:bg-white/5 transition-colors duration-150"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    About
                  </Link>
                  
                  <Link
                    to="/#services"
                    className="block px-3 py-2 text-base font-medium text-white/90 hover:text-white rounded-md hover:bg-white/5 transition-colors duration-150"
                    onClick={e => scrollToSection(e, "#services")}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        scrollToSection(e, "#services");
                      }
                    }}
                  >
                    Services
                  </Link>
                  
                  <Link
                    to="/blog"
                    className="block px-3 py-2 text-base font-medium text-white/90 hover:text-white rounded-md hover:bg-white/5 transition-colors duration-150"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Blog
                  </Link>

                  <button
                    onClick={openContactModal}
                    className="w-full mt-8 inline-flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-indigo-600 px-5 py-3 rounded-full text-base font-semibold text-white transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
                  >
                    <span>Let's Talk</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </button>
                </nav>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      
      <AnimatePresence>
        {isContactModalOpen && (
          <ContactModal 
            isOpen={isContactModalOpen} 
            onClose={closeContactModal} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Header; 