import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Services: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  
  const services = [
    {
      title: 'Engagement Consulting',
      description: 'Helping organizations build effective customer relationships through data-driven engagement strategies, personalized communication, and measurable outcomes.',
      bullets: [
        'Engagement strategy development',
        'Customer journey optimization',
        'Communication personalization'
      ]
    },
    {
      title: 'Marketing Automation',
      description: 'Building robust marketing automation ecosystems designed to deepen customer engagement, streamline processes, and deliver measurable value quickly.',
      bullets: [
        'Customer journey mapping',
        'Engagement optimization',
        'Analytics & reporting'
      ]
    },
    {
      title: 'TCPA Compliance',
      description: 'Providing strategic guidance and hands-on support to ensure your marketing communications fully meet TCPA standards without compromising effectiveness or scalability.',
      bullets: [
        'Regulatory compliance audit',
        'Documentation & training',
        'Risk mitigation strategies'
      ]
    },
    {
      title: 'Engagement Data Consulting',
      description: 'Transforming engagement data into actionable insights that drive better messaging, targeting, and member outcomes.',
      bullets: [
        'Data strategy & governance',
        'Predictive modeling for opt-outs and conversions',
        'Engagement analytics & KPI frameworks'
      ]
    },
    {
      title: 'Product Coaching',
      description: 'Guiding product teams to build, scale, and optimize digital platforms that align with business goals and user needs.',
      bullets: [
        'Product strategy & roadmapping',
        'Go-to-market planning',
        'Team coaching & agile delivery'
      ]
    },
    {
      title: 'Experimentation & Testing',
      description: 'Empowering organizations to make evidence-based decisions through structured testing and continuous improvement.',
      bullets: [
        'A/B & multivariate testing design',
        'Behavioral segmentation',
        'Outcome measurement & test governance'
      ]
    },
    {
      title: 'Channel Engagement Strategy',
      description: 'Designing and optimizing messaging strategies across key channels to maximize reach, response, and impact.',
      bullets: [
        'IVR strategy and scripting optimization',
        'SMS journey design & compliance',
        'Email lifecycle & personalization planning'
      ]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 25,
        mass: 0.5
      }
    }
  };

  const currentServices = services.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const totalPages = Math.ceil(services.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  return (
    <section className="py-20 bg-slate-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">How I Can Help</h2>
          <p className="text-xl text-white/70 max-w-3xl mx-auto">
            Transforming digital engagement through expertise, innovation, and proven results
          </p>
        </motion.div>

        <div className="relative">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentPage}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="grid grid-cols-1 md:grid-cols-3 gap-8"
            >
              {currentServices.map((service, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-slate-800/50 backdrop-blur-sm border border-white/10 rounded-2xl p-6 hover:border-white/20 transition-colors duration-300"
                >
                  <h3 className="text-xl font-semibold text-white mb-4">{service.title}</h3>
                  <p className="text-white/70 mb-4">{service.description}</p>
                  <ul className="space-y-2">
                    {service.bullets.map((bullet, bulletIndex) => (
                      <li key={bulletIndex} className="flex items-start gap-2 text-white/80">
                        <svg className="w-5 h-5 text-green-400 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>{bullet}</span>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>

          {/* Navigation Controls */}
          <div className="flex items-center justify-center gap-4 mt-8">
            <button
              onClick={prevPage}
              className="p-2 rounded-full bg-slate-800/50 border border-white/10 hover:border-white/20 transition-colors duration-300"
              aria-label="Previous page"
            >
              <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            
            <div className="flex gap-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index)}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    currentPage === index ? 'bg-white w-4' : 'bg-white/30'
                  }`}
                  aria-label={`Go to page ${index + 1}`}
                />
              ))}
            </div>

            <button
              onClick={nextPage}
              className="p-2 rounded-full bg-slate-800/50 border border-white/10 hover:border-white/20 transition-colors duration-300"
              aria-label="Next page"
            >
              <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services; 